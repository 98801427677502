.doctor-layout {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.my-badge {
  position: absolute;
  font-size: 10px;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  background-color: #ef6262;
  border-radius: 50%;
}

.doctor-header-top {
  font-family: Montserrat;
  min-height: 60px;
  color: var(--primary);
  background-color: '#fff';
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  padding: 10px 10px;
}
.header-left {
  display: flex;
  align-items: center;
}
.header-link {
  color: var(--black);
  font-weight: 600;
  margin-right: 20px;
}
.header-link.is-selected {
  border-bottom: 3px solid var(--primary);
}

.header-link:hover {
  color: #737373;
}
.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.user-background {
  height: 40px;
  min-width: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-item: center;
}

.header-bottom {
  background-color: var(--primary);
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  color: white;
}
.header-bottom-inner{
  margin: auto;
}
.header-top-hidden {
  display: none;
}
.header-log-info p {
  padding: 0;
  margin: 0px 15px;
  color: black;
  font-weight: 500;
}
.header-log-info span {
  margin-right: 6px;
}
.user-background:hover .profile-dropdown {
  visibility: visible;
  transform: translateY(0px);
}

.profile-dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 200;
  position: absolute;
  right: 10px;
  top: 65px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  visibility: hidden;
  transition: all 0.2s ease;
}
.profile-dropdown a {
  margin: 10px 25px;
  font-weight: 400;
}
.profile-dropdown span {
  font-weight: 600;
}
.dropdown-top {
  border-bottom: 2px solid #d9d9d9;
  padding: 10px 25px;
}

@media (max-width: 1423px) {
  .header-log-info {
    display: none;
  }
  /* .header-top-hidden {
      display: flex;
      height: 60px;
      background-color: '#fff';
      align-items: center;
      padding: 0 10px;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
    } */
  .menu-hidden-active {
    width: 30%;
    height: 100%;
    background-color: white;
    z-index: 100;
  }
  .menu-hidden {
    display: none;
  }
}
@media (max-width: 1160px) {
  .doctor-header-top {
    /* display: none; */
    display: flex;
    font-size: 12px;
  }
  .header-top-right {
    display: none;
  }
  .header-top-left {
    display: none;
  }
  .menu-hidden-active {
    width: 30%;
    height: 100%;
    background-color: white;
    z-index: 100;
  }
  .menu-hidden {
    display: none;
  }

  .header-top-hidden {
    display: flex;
    height: 60px;
    background-color: '#fff';
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    position: relative;
  }
}

.menu-sidebar {
  width: 335px;
  height: 100%;
  background-color: white;
  position: absolute;
  left: 0;
}
.dropdown-menu{
  position: relative;
}

.dropdown-item {
  width: 205px;
  padding: 20px 22px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 200;
  position: absolute;
  top: 40px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  visibility: hidden;
  transition: all 0.2s ease;
}
.dropdown-menu:hover .dropdown-item {
  visibility: visible;
}