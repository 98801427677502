.mobile-container {
  width: 90%;
  margin: auto;
}
.mobible-inner {
  margin-top: 25px;
}
.search-mobile_wrapper {
  background-color: #f5f2f2;
  width: 100%;
  min-height: 70px;
  margin-top: 45px;
  margin-bottom: 20px;
  border: 1px solid var(--borderColor);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px 10px;
}
.mobile-btn {
  color: black !important;
  font-weight: 400;
  font-size: 16px;
  font-weight: 300;
  padding: 0;
  font-size: 10px;
}
.mobile-note-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.mobile-note > span {
  font-size: 11px;
}
.mobile-note-sp {
  padding: 0 10px;
  background-color: var(--primary);
  border-radius: 15px;
  font-size: 11px;
  color: white;
  margin-right: 7px;
}
.table-Mobile {
  margin-top: 20px;
}
.table-mobile-row {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: flex-start;
  border: solid 2px #d1d1d1;
  margin-bottom: 10px;
  padding-top: 7px;
}
.table-mobile-td {
  width: 25%;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* word-break: break-all; */
  justify-content: center;
  flex-direction: column;
}
.search-item-btn-mobile {
  color: black !important;
  font-weight: 400;
  font-size: 13px;
  font-weight: 400;
  padding: 0;
  margin-right: 5px;
  background: none;
  border: none;
}
.dropdown-search-feature-mobile {
  width: 100%;
  background-color: white;
  border: solid 1px #bbbbbb;
  border-radius: 5px;
  padding: 20px 32px;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-content: space-around;
  max-height: 51vh;
  overflow: scroll;
}

.space-dropdown {
  width: 100%;
  height: 1px;
  /* margin: 41px 0px; */
  margin-top: 54px;
  background-color: #cbcbcb;
}

.submit-search-wapper-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 10px 5px;
  border-top: 1px solid #cacacf;
}
.submit-search-btn-mobile {
  background-color: var(--primary);

  border: none;
  border-radius: 3px;
  padding: 5px 15px;
  margin: 10px 5px;
  color: white;
}
.clear-search-btn-mobile {
  background-color: white;
  border: solid 1px var(--primary);
  border-radius: 3px;
  padding: 5px 15px;
  margin: 10px 5px;
  color: var(--primary);
}
.rdrCalendarWrapper {
  height: 335px;
}
label {
  padding: 0px 8px;
}

.dropdown-search-feature-mobile .rdrMonthAndYearWrapper {
  height: 0;
}

.rdrDays,
.rdrMonth,
.rdrMonth .rdrWeekDays {
  width: 100% !important;
}
@media only screen and (max-width: 596px) {
  .main-calender {
    width: auto;
    left: 20px !important;
    right: 20px !important;
  }
}
.rdrWeekDay {
  margin: 0;
}
.dropdown-search-feature-mobile .content-row {
  display: flex;
  flex-direction: column;
  align-items: normal;
}
/* .content-row{
  display: flex;
  flex-direction: column
} */

.dropdown-search-feature-mobile .specify-time label {
  font-size: 12px;
  padding-left: 0px;
}
.dropdown-search-feature-mobile .specify-time .ant-checkbox-wrapper {
  margin-left: 8px;
}
.dropdown-search-feature-mobile .content-row .hourly-label {
  margin-left: 0.5rem;
}

.dropdown-search-feature-mobile .clinic-open-container {
  overflow-y: auto;
  padding: 10px 0;
}
@media (max-width: 280px) {
  .table-mobile-row {
    font-size: 11px;
  }
  .table-action .ant-btn {
    font-size: 11px;
  }
}
.doctor-layout-body {
  margin: 0px;
}
