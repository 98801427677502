.doctor-layout {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.doctor-layout-header {
  height: 60px;
  color: var(--primary);
  background-color: '#fff';
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-bottom: 1px solid #eee;
}

.doctor-layout-logo {
  width: 205px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}

.doctor-layout-spacer {
  flex: 1;
}

.doctor-layout-cart {
  display: flex;
  justify-content: flex-end;
}

.doctor-user-display{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: black;
  margin-right: 30px;
  margin-left: -10px;
}

.user-box{
  width: 45px;
  height: 45px;
  background-color: var(--primary);
  border-radius: 23px;
  padding: 0px 6px;
  margin-right: 10px;
  margin-top: -10px;
}

.doctor-layout.true > .doctor-layout-body {
  padding-left: 335px;
}

@media (max-width: 768px) {

.doctor-layout.true > .doctor-layout-body {
    padding-left: 0
}
  }

.doctor-layout.true > .doctor-layout-menu {
  transform: translateX(0);
}

@media (max-width: 768px) {

.doctor-layout.true > .doctor-layout-menu .doctor-layout-menu-bg {
      width: 0;
      opacity: 0
  }
    }

@media (max-width: 768px) {

.doctor-layout.true > .doctor-layout-menu {
    transform: translateX(-100%)
}
  }

.doctor-layout-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  transition: padding-left 0.4s ease-in-out;
  background-color: var(--mainBg);
  justify-content: space-between;
}

.doctor-layout-menu {
  position: fixed;
  width: 335px;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  box-shadow: var(--boxShadow);
  z-index: 500;
  overflow-y: auto;
}

@media (max-width: 768px) {

.doctor-layout-menu {
    transition: transform 0.4s ease-in-out;
    transform: translateX(0)
}
  }

.doctor-layout-menu-profile {
    height: 100%;
    padding: 5px 0;
    background-color: #fff;
  }

.doctor-layout-menu-profile .p-accordion-toggle-icon{
      color: var(--primary) !important;
    }

@media (max-width: 768px) {

.doctor-layout-menu-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #000;
      transition: opacity 0.4s ease-in-out;
      opacity: 0.2;
      z-index: 10
  }
    }

.doctor-layout-menu-link {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  position: relative;
}

@media (max-width: 768px) {

.doctor-layout-menu-link {
    padding: 15px 20px;
    font-size: 16px
}
  }

.doctor-layout-menu-link.is-selected {
    background-color: #ffebeb;
    color: #666;
  }

.doctor-layout-menu-link.is-selected:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--primary);
    }

.doctor-layout-menu-link:hover {
    background-color: #ffebeb;
    color: #666;
  }

.doctor-layout-menu-link:hover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--primary);
    }

.doctor-layout-menu-link i {
    color: var(--primary);
    margin-right: 10px;
  }

.user-display-dropdown{
  color: black;
  font-size: 14px;
}

.doctor-layout-menu-divider {
  height: 1px;
  background-color: #eee;
  margin: 20px 0;
}

@media (max-width: 768px) {

.doctor-layout-menu-divider {
    margin: 10px 0
}
  }

.doctor-layout-menu .p-accordion-header a {
  background-color: white !important;
  /* color: #fff !important; */
  color: black;
  /* border: solid 1px #e3e3e3 !important; */
  outline: none !important;
  box-shadow: none !important;
  font-size: 18px;
}

.my-badge {
  position: absolute;
  font-size: 10px;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  background-color: #ef6262;
  border-radius: 50%;
}

.p-accordion-header-text {
  text-transform: capitalize;
}

@media (max-width: 768px) {

.p-accordion-tab {
    font-size: 12px
}
  }

.doctor-layout-menu .pi-caret-right {
  color: var(--primary) !important;
  padding: 15px 20px;
}

.doctor-layout-menu .doctornav-group a:not(.doctor-layout-menu-link.is-selected) {
  background-color: #fff !important;
  color: #666 !important;
  font-size: 16px !important;
  padding: 0.881em 1em;
}

.doctor-layout-menu .doctornav-group .p-accordion-header-text {
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.doctor-layout-menu .doctornav-group .p-accordion-header-text span {
  position: relative;
  bottom: 3px;
}

.doctor-layout-menu .doctornav-group a:hover {
  background-color: #ffebeb !important;
}

.badge {
  position: absolute;
  top: 50%;
  right: 10px;
  border-radius: 10px;
  background-color: var(--primary);
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.layout-logo-privacy {
  margin-left: 50px;
  width: 200px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}

@media (max-width: 768px) {

.layout-logo-privacy {
    width: 150px;
    margin-left: 10px
}
  }

.footer {
  font-weight: bold;
  text-align: center;
  padding: 5px;
  color: #fff;
  background-color: var(--primary);
}

.footer:hover {
    color: #fff;
  }

@media (max-width: 768px) {

.footer {
    font-size: 11px
}
  }

.carrer-description .p-inputtextarea {
  margin: 0 !important;
}

.new-message::after {
  content: '•';
  color: #f03a6c;
  top: -10px;
  position: relative;
}

.new-message:hover::after {
  content: '•';
  background-color: #ffebeb;
  color: #f03a6c;
  top: -10px;
  position: relative;
}

.close-img-btn {
  border: solid 1px #f03a6c;
  color: #f03a6c;
  position: relative;
  right: -40px;
  z-index: 2;
}

.new-message.is-selected {
    content: '•';
    background-color: #ffebeb;
  }

.new-message.is-selected:after {
      content: '•';
      background-color: #ffebeb;
      color: #f03a6c;
      top: -10px;
      position: relative;
    }

.new-message:hover {
    content: '•';
    background-color: #ffebeb;
  }

.new-message:hover:after {
      content: '•';
      background-color: #ffebeb;
      color: #f03a6c;
      top: -10px;
      position: relative;
    }

.tag-name {
  margin-left: 5px;
  background-color: #ff2575;
  padding: 0 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
}

.fake-menu:hover{
  background-color: #fff;

}

.fake-menu:hover:after{
  height: 0;
  width: 0
}

.doctor-layout-menu-profile, .doctor-layout-menu-link {
  padding: 0.5em 1em;
}

.p-accordion-content .user-display-dropdown {
  margin-bottom: 0;
}

.doctor-layout-menu-link:not(.p-accordion-content .doctor-layout-menu-link), .doctor-layout-menu .p-accordion-header span {
  font-weight: 100;
}

.doctor-layout-menu {
  background-color: white;
}

a.doctor-layout-menu-link {
  color: black;
}

.doctor-layout-menu .doctornav-group a {
  padding: 0.5em 1em !important;
}

.doctor-layout.true .staff-layout-menu {
  width: 250px;
}

@media (max-width: 768px) {

.doctor-layout.true .staff-layout-menu {
    min-width: 250px
}
  }

.staff-layout-body {
  flex: 1;
  padding: 0px 20px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 60px);
  transition: padding-left 0.4s ease-in-out;
  background-color: var(--mainBg);
  min-width: 800px;
  position: relative;
  z-index: 0;
}

.staff-layout-content {
  display: flex;
}

@media (max-width: 768px) {

.staff-layout-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}
  }

.staff-layout-header {
  height: 60px;
  color: var(--primary);
  background-color: '#fff';
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-bottom: 1px solid #eee;
}

.staff-layout-logo {
  width: 200px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}

.staff-layout-menu {
  width: 0;
  height: calc(100vh - 60px);
  transition: width 0.4s ease-in-out;
  overflow: hidden;
  border-right: 1px solid #eee;
  background-color: #fff;
  z-index: 0;
  overflow-y: auto;
}

.staff-layout-menu-inner {
    width: 250px;
    padding: 20px 0;
    background-color: #fff;
  }

.staff-layout-menu-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  position: relative;
}

.staff-layout-menu-link.is-selected {
    background-color: #ffebeb;
    color: #666;
  }

.staff-layout-menu-link.is-selected:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--primary);
    }

.staff-layout-menu-link:hover {
    background-color: #ffebeb;
    color: #666;
  }

.staff-layout-menu-link:hover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--primary);
    }

.staff-layout-menu-link i {
    color: var(--primary);
    margin-right: 10px;
  }

.staff-layout-menu-divider {
  height: 1px;
  background-color: #eee;
  margin: 20px 0;
}

.staff-layout-menu .p-accordion-header a {
  background-color: var(--primary) !important;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 18px;
}

.staff-layout-menu .pi-caret-right {
  color: #ffebeb !important;
}

.staff-layout-menu .doctornav-group a,
.staff-layout-menu .history-nav-group a {
  background-color: #fff !important;
  color: #666 !important;
  font-size: 16px !important;
}

.staff-layout-menu .doctornav-group .p-accordion-header-text,
.staff-layout-menu .history-nav-group .p-accordion-header-text {
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.staff-layout-menu .doctornav-group .p-accordion-header-text span,
.staff-layout-menu .history-nav-group .p-accordion-header-text span {
  position: relative;
  bottom: 3px;
}

.staff-layout-menu .doctornav-group a:hover,
.staff-layout-menu .history-nav-group a:hover {
  background-color: #ffebeb !important;
}

.mt-5 {
  margin-bottom: 15px !important;
}

.mb-0 {
  margin-bottom: 0 !important;;
}

.mb-3 {
  margin-bottom: 12px !important;;
}

.pt-0 {
  padding-top: 0 !important;;
}

.pt-3 {
  padding-top: 12px !important;;
}

.pb-0 {
  padding-bottom: 0 !important;;
}

.tr-checkboxUpdateProfile {
  margin-left: 100px !important;
}

.label-checkboxUpdateProfile {
  margin-left: 15px;
}

.breakLine {
  margin-bottom: 170px !important;
}

.inline-row {
  display: flex;
  align-items: center;
}

.inline-row_container {
  width: 50%;
}

.inline-multiple-row_container {
  width: 10% !important;
  min-width: 50px;
}

@media screen and (max-width: 768px) {
  .td.inline-row {
    width: calc(70% - 40px) !important
  }
}

.margin-0 {
  margin: 0 !important;
}

.career-history_doctor {
  display: flex;
  align-items: center;
  margin-left: 180px;
  justify-content: flex-end;
}

.career-history-field {
  margin: auto 0;
  padding-left: 140px;
}

.career-history-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-5 {
  margin-left: 5px;
}

.label-carrerHistory {
  margin-left: 15px;
  font-weight: 600;
}

.career-history-container .carrer-description .p-inputtextarea {
  margin-bottom: 50px;
  width: 150px;
}

.reson-label_check {
  margin-top: -160px !important;
}

.spacing {
  padding: 10px 0;
}

.career-history-container .carrer-description .tr .td {
  padding: 0 8px !important;
}

.carrer-description .tr .td .p-inputtext {
  padding: 6px 0 !important;
}

.career-history-container .tail-text {
  padding: 0 5px;
}

.inTroduceBy-container {
  width: 50% !important;
  margin-left: 32%;
}

.errors-container {
  display: flex;
  flex-direction: column;
  padding-left: 35px;
}

.hourly-container {
  width: 100%;
}

.margin-non {
  margin: 0 !important;
}

.pd-5 {
  padding: 0 5px;
}

.labelhourly_wage-checkbox {
  padding: 0 !important;
  margin: 0 !important;
  width: 0% !important;
}

.pd-5 {
  /* padding: 0 !important; */
}

.border-black {
  /* border: 1px solid #000 */
}

.history-nav-group .icon-history {
  display: flex;
  align-items: center;
}

.history-nav-group .icon-history i {
  color: var(--primary);
  margin-right: 10px;
  margin-left: 3px;
}

.title-week-day {
  font-weight: 500 !important;
  text-align: right;
  padding-right: 40px;
  width: 30%;
}

.multiple-checkbox-container {
  /* display: flex; */
  /* justify-content: center !important; */
  /* padding-left: 15px; */
}

.toggle-published .ant-switch-checked {
  background-color: #f03a6c;
}

.new-schedule-cancel {
  position: absolute;
  width: 14.5%;
  background-color: #ff2575;
  padding: 0 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 8px;
  margin-top: -14%;
  margin-left: 70%;
}

.new-waiting-recruitment-shift {
  position: absolute;
  width: 14.5%;
  background-color: #ff2575;
  padding: 0 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 8px;
  margin-top: -14%;
  margin-left: 65%;
}

.new-recruitment-doctor {
  width: 14.5%;
  background-color: #ff2575;
  padding: 0 10px 2px;
  border-radius: 25px;
  color: #fff;
  font-size: 8px;
  vertical-align: text-top;
  margin-left: 0.5rem;
}

.new-work-outside {
  position: absolute;
  display: flex;
  justify-content: center;
  min-width: 20px;
  background-color: #ff2575;
  padding: 0 3px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  margin-top: -14%;
  margin-left: 65%;
}

.new-ws {
  position: relative;
  background-color: #ff2575;
  padding: 0 5px;
  border-radius: 25px;
  color: #fff;
  font-size: 10px;
  left: -10px;
  top: -5px;
}

.new-message.is-selected {
    content: '•';
    background-color: #ffebeb;
  }

.new-message.is-selected:after {
      content: '•';
      background-color: #ffebeb;
      color: #f03a6c;
      top: -10px;
      position: relative;
    }

.new-message:hover {
    content: '•';
    background-color: #ffebeb;
  }

.new-message:hover:after {
      content: '•';
      background-color: #ffebeb;
      color: #f03a6c;
      top: -10px;
      position: relative;
    }

.table-custom th {
  white-space: normal !important;
}

.table-custom a {
  color: #000 !important;
}

.table-custom td {
  text-align: center;
}

.changed .p-dropdown-label {
  color: rgb(102, 102, 102) !important;
}

.doctor-subsidy-container {
  display: flex;
  align-items: center;
  /* padding-left: 15px; */
  padding: 5px 0;
  width: 80%;
  justify-content: space-between;
}

.doctor-subsidy-container .p-inputtext {
  width: 100%;
}

.doctor-subsidy-container .doctor-subsidy__item {
  display: flex;
  align-items: center;
}

.doctor-subsidy-container .doctor-subsidy__item .title {
  padding-right: 15px;
}

/* .doctor-subsidy-container:nth-child(2){
  padding: 0 20px;
} */

.display-flex-time {
  display: flex !important;
}

.label-department {
  padding: 10px 0;
  font-weight: bold;
}

.label-day {
  margin-right: 8px;
  font-weight: bold;
}

.time-container {
  padding: 0 5px;
}

.list-progress {
  position: fixed;
  top: 70px;
  right: 70px;
  z-index: 1000;
  height: 300px;
  width: 50vh;
  overflow-y: auto;
}

.list-progress::-webkit-scrollbar {
  display: none;
}

.login-bg {
  background-color: #eee;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.login-box {
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 0;
  box-shadow: 0 4px 4px #ccc;
}

.login-box.is-medium {
    max-width: 500px;
    padding: 30px;
  }

.login-box h3 {
    color: #818c92;
    text-align: left;
    font-size: 20px;
    padding-left: 15px;
    margin: 15px 0;
  }

.login-inner {
  width: 90%;
  max-width: 300px;
  margin: 0 auto;
}

.login-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary);
}

.login-title-label {
  color: #818c92;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.login-label {
  font-size: 14px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.login-input {
  position: relative;
  margin-bottom: 40px;
}

.login-desc {
  margin-bottom: 20px;
  text-align: center;
}

.login-input input {
  width: 100%;
}

.login-button {
  width: 100%;
  padding: 12px 0;
  max-width: 300px;
}

.login-button span {
  font-size: 18px;
}

.login-bottom {
  text-align: center;
  padding-top: 30px;
  margin: 0 auto;
}

.login-bottom a {
  color: #666;
  font-size: 12px;
}

.login-bottom .login-bottom-content .sign-up-link span{
  color: #007ad9;
  font-size: 18px;
}

.login-bottom .login-bottom-content .sign-up-link{
  margin-bottom: 10px;
}

.login-bottom .login-bottom-content .sign-up-link .p-button:enabled:hover{
  background-color: #E8F0FE;
  border-color: #818c92
}

.login-alert {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 5px 0 0 5px;
  color: var(--red);
}

.login-link {
  padding: 1%;
}

.calendar-container {
  position: relative;
}

.fc-today {
  background: none !important;
}

.calendar-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 3.5rem;
}

.calendar-control-incentive {
  margin-bottom: 10px;
  margin-left: 8%;
  margin-top: 10px;
  width: 84%;
  display: flex;
  justify-content: space-between;
}

.calendar-title {
  font-size: 20px !important;
  line-height: 33px;
  font-weight: bold;
}

.available-shift-container .ant-tabs-tab-btn:hover {
  color: var(--black) !important;

}

.doctor-container {
  display: block;
  justify-content: space-between;
  align-items: stretch;

}

@media (max-width: 1024px) {

.doctor-container {
    flex-direction: column

}
  }

.show.doctor-left {
    width: 100%;
  }

@media (max-width: 1024px) {

.show.doctor-left {
      width: 100%;
      overflow: hidden;
      padding: 0
  }
    }

.show.doctor-content {
    width: 100%;
    padding: 0rem 5rem 5rem;
    opacity: 1;
  }

@media (max-width: 1024px) {

.show.doctor-content {
      width: 100%;
      margin-left: 0
  }
    }

.doctor-left {
  width: 100%;
  transition: width 0.4s linear;
  padding: 3rem 1.5rem;
}

@media (max-width: 1024px) {

.doctor-left {
    width: 100%;
    transition: none;
    padding: 3px
}
  }

.doctor-content {
  width: 0;
  transition: opacity 0.4s ease-in-out;
  overflow: hidden;
  opacity: 0;
  padding: 3px;
}

@media (max-width: 1024px) {

.doctor-content {
    transition: none
}
  }

.doctor-content-inner {
  }

.doctor-content .box {
    height: 100%;
  }

@media (max-width: 1024px) {

.doctor-content .box {
      height: auto
  }
    }

.doctor-content .boxIe {
    height: 1000px;
  }

@media (max-width: 1024px) {

.doctor-content .boxIe {
      height: auto
  }
    }

.doctor-content h2 {
    margin: 0;
    line-height: 33px;
  }

@media (max-width: 768px) {

.doctor-content h2 {
      font-size: 17px
  }
    }

@media (max-width: 768px) {

.doctor-content .content-table th {
        font-size: 12px
    }
      }

.doctor-content .content-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

@media (max-width: 768px) {

.fc table {
    width: 100%;
    font-size: 0.9em
}
  }

.fc-event {
  border-radius: 0 !important;
}

.fc-toolbar h2 {
  font-size: 20px !important;
  line-height: 33px;
}

.fc-past {
  opacity: 0.8;
}

.fc-past .fc-day-number {
  color: #666;
}

.fc-widget-header .fc-sun {
  color: #ef5956;
  font-weight: bold;
}

.fc-sun .fc-day-number {
  color: #ef5956;
  font-weight: bold;
}

.fc-widget-header .fc-sat {
  color: #4969f8;
  font-weight: bold;
}

.fc-sat .fc-day-number {
  color: #4969f8;
  font-weight: bold;
}

.fc-sun {
  background-color: rgba(236, 31, 38, 0.1);
}

.fc-sat {
  background-color: rgba(181, 227, 243, 0.2);
}

.fc-day-number {
  font-size: 12px !important;
  text-align: center !important;
}

@media (var --sp) {

.fc-day-number {
    font-size: 10px !important;
    text-align: center !important
}
  }

.event .fc-time {
  display: none;
}

.event .fc-title {
  display: none;
}

.holiday .fc-title {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #acceda;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
}

.holiday .fc-time {
  display: none;
}

.holiday {
  pointer: default;
  pointer-events: none;
}

@media (max-width: 768px) {

.holiday {
    touch-action: unset
}
  }

.tag-name-new,
.tag-name-update {
  position: absolute;
  top: 0;
}

.tag-name-new .fc-title {
  display: flex;
  justify-content: center;
  width: 50px;
  color: #fff;
  background-color: #ff2575;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 25px;
}

.tag-name-new .fc-time {
  display: none;
}

.tag-name-new {
  pointer: default;
  pointer-events: none;
}

@media (max-width: 768px) {

.tag-name-new {
    touch-action: unset
}
  }

.tag-name-update .fc-title {
  display: flex;
  justify-content: center;
  width: 50px;
  color: #fff;
  background-color: #ff9a23;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 25px;
}

.tag-name-update .fc-time {
  display: none;
}

.tag-name-update {
  pointer: default;
  pointer-events: none;
}

@media (max-width: 768px) {

.tag-name-update {
    touch-action: unset
}
  }

.tag-name-special-salary {
  pointer: default;
  pointer-events: none;
}

@media (max-width: 768px) {

.tag-name-special-salary {
    touch-action: unset
}
  }

.tag-name-special-salary .fc-time {
  display: none;
}

.tag-name-special-salary .fc-title {
  display: flex;
  color: #fff;
  background-color: #ff2575;
  font-size: 11px;
  font-weight: bold;
  justify-content: center;
}

.tag-name-special-salary-mobile .fc-title{
  font-size: 9px !important;
}

.event {
  width: 100%;
  height: auto;
  min-height: 30px;
  margin: 0;
  cursor: pointer;
  padding: 0 !important;
  box-sizing: border-box;
  :relative ;
  border: none !important;
}

.event::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    box-sizing: border-box;
    /* border: 4px solid var(--green) !important; */
  }

@media (max-width: 768px) {

.event::after {
      width: 14px;
      height: 14px;
      min-width: 14px
  }
    }

.event-full::after {
    border: 4px solid var(--yellow) !important;
  }

.event-only-as::after {
    border: 4px solid var(--green) !important;
  }

@media (max-width: 768px) {

.event-only-as::after {
      border: 3px solid var(--green) !important
  }
    }

.event-only-accepted::after {
    background-color: var(--primary) !important;
  }

.event-only-not-accepted {
  font-size: 0em;
}

.event-only-not-accepted::after {
    background-color: var(--yellow) !important;
  }

/* background-color: #fcf8e3 !important; */

.event-selected::before {
    content: '';
    position: absolute;
    top: -21px;
    left: 0;
    width: 100%;
    height: calc(100% + 28px);
    /* background-color: #fcf8e3; */
    z-index: -1;
  }

@media (max-width: 1800px) {

.event-selected::before {
      height: calc(100% + 23px);
  }
    }

@media (max-width: 768px) {

.event-selected::before {
      height: calc(100% + 23px);
  }
    }

.p-dropdown-panel {
  top: 35px !important;
}

.detail-view {
  border: 1px solid var(--primary);
  border-radius: 10px;
  overflow: hidden;
}

.detail-view th {
    background-color: #f8f8f8 !important;
  }

.detail-view i {
    color: var(--primary);
  }

.tool-event::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    box-sizing: border-box;
  }

@media (max-width: 768px) {

.tool-event::after {
      width: 25px;
      height: 25px;
      min-width: 25px
  }
    }

/* border: 4px solid var(--green) !important; */

.mobile-accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.mobile-accordion .active,
  .mobile-accordion .accordion:hover {
    background-color: #ccc;
  }

.mobile-header {
  display: flex;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
}

.mobile-accordian-body {
  background-color: white;
  display: none;
  overflow: hidden;
}

.mobile-work-schedule-item {
  position: relative;
  padding: 5px;
  border: 1px solid var(--gray);
  border-width: 0 1px 1px 1px;
}

.mobile-work-schedule-item .clinic-name {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: bold;
    /* color: blue;
    text-decoration: underline; */
  }

.mobile-work-schedule-item .action {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

tr:first-child > td > .fc-day-grid-event {
  margin-top: 0px;
}

.action-group {
  display: flex;
  justify-content: center;
}

.single-available-shift {
  width: 100%;
}

.single-available-shift th {
  text-align: right !important;
}

.alert-login {
  /* padding: 0 10%; */
  color: #db7093 !important;
}

.alert-login .modal-bottom {
  justify-content: center !important;
}

.alert-login .modal-title {
  color: #465564 !important;
  font-weight: bold !important;
}

.specify-start-label{
  padding-left: 0px;
}

body .p-button {
  background-color: #fff;
  color: var(--gray);
  border-color: var(--gray);
  transition: 0.4s ease-in-out;
}

body .p-button:enabled:hover {
    background-color: var(--gray);
    color: #fff;
    border-color: transparent;
  }

body .p-button:enabled:focus {
    box-shadow: none !important;
  }

.p-datatable {
  line-height: 1.2;
}

@media screen and (max-width: 480px) {

.p-datatable {
    line-height: 1.2
}
  }

.p-datatable-row > td {
  font-size: 12px;
}

@media screen and (max-width: 480px) {

.p-datatable-row > td {
    font-size: 11px
}
  }

.grad-wrap {
  display: block;
  background-color: transparent;
}

.grad-btn {
}

.grad-item {
  position: relative;
  overflow: hidden;
  height: 30px; /*隠した状態の高さ*/
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grad-item::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /*グラデーションで隠す高さ*/
  background: linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.9) 50%, #fff 100%);
  content: "";
}

.grad-trigger {
  display: none; /*チェックボックスは常に非表示*/
}

.grad-trigger:checked + .grad-btn {
  display: none; /*チェックされていたら、grad-btnを非表示にする*/
}

.grad-trigger:checked ~ .grad-item {
  height: auto; /*チェックされていたら、高さを戻す*/
}

.grad-trigger:checked ~ .grad-item::before {
  display: none; /*チェックされていたら、grad-itemのbeforeを非表示にする*/
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #848484;
  display: inline;
  font-size: 0.9em;
}

@media screen and (max-width: 480px) {

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    font-size: 0.9em
}
  }

.p-column-title {
  font-size: 11px;
}

@media screen and (max-width: 480px) {

.p-column-title {
    font-size: 9px;
    text-align: center
}
  }

.pi-filter {
  margin: 5px 5px 0px 10px;
}

.p-inputtext {
  width: 100%;
}

.p-datatable-frozen-view {
  border-right: 1px solid #ccc;
}

.p-calendar .p-datepicker {
  top: 100% !important;
}

.p-multiselect .p-multiselect-panel {
  top: 100% !important;
}

.p-button-normal {
  padding: 5px 0;
  font-size: 13px !important;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(221, 221, 221) !important;
}

.p-button-normal:hover {
    background-color: #ccc !important;
  }

.p-button-normal-available {
  padding: 5px 0;
  font-size: 13px !important;
  background-color: #ffebeb !important;
  color: #000 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(221, 221, 221) !important;
}

.p-button-normal-available:hover {
    background-color: #ccc !important;
  }

.p-button-normal-empty {
  padding: 5px 0;
  font-size: 13px !important;
  background-color: #F0FFFF!important;
  color: #000 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(221, 221, 221) !important;
}

.p-button-normal-empty:hover {
    background-color: #ccc !important;
  }

.p-button-gray {
  padding: 5px 0;
  font-size: 13px !important;
  background-color: #F0F0F0!important;
  color: #000 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(221, 221, 221) !important;
}

.p-button-gray:hover {
    background-color: #D3D3D3 !important;
  }

@media (max-width: 768px) {

.react-router-modal__container {
    width: 100vw
}
  }

@media (max-width: 768px) {
    .sp-button .p-button-text {
    }
  }

.doctor-layout-menu-profile a {
    position: relative;
  }

.doctor-layout-menu-profile .p-accordion-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

th {
  white-space: nowrap;
  line-height: 20px;
  padding: 10px 5px !important;
}

@media (max-width: 768px) {

th {
    padding: 9px 5px !important
}
  }

input:focus {
    box-shadow: none !important;
  }

body .p-inputtext {
  -webkit-font-smoothing: subpixel-antialiased !important;
}

.p-focus {
  box-shadow: none !important;
  outline: none !important;
}

.p-accordion-content {
  border: none !important;
  padding: 0 !important;
}

.p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: #fff !important;
}

.p-dialog-mask {
  display: none;
}

.modal-container .p-dialog {
    width: 85%;
  }

.p-dialog {
  width: 70%;
}

@media (max-width: 768px) {

.p-dialog {
    width: 90%
}
  }

.p-dialog::after {
    content: '';
    position: absolute;
    top: -50vh;
    left: -50vw;
    width: 150vw;
    height: 150vh;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
  }

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: var(--primary);
  border-color: var(--primary);
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
  background-color: var(--primary);
  border-color: var(--primary);
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--thColor);
  color: #000;
}

body .p-disabled,
body .p-component:disabled {
  opacity: 1;
  border: 1px solid #eee;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a {
  background-color: var(--primary);
  border-color: var(--primary);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a {
  background-color: var(--primary);
  border-color: var(--primary);
}

body .p-tabview .p-tabview-panels {
  border-width: 0;
  border-top-width: 1px;
}

.accepted-false {
  white-space: nowrap;
}

.is-disabled {
  border: none !important;
}

.is-disabled .p-dropdown-trigger-icon {
    display: none;
  }

.is-disabled input {
    border: none !important;
  }

.p-disabled {
  box-shadow: none;
}

.p-disabled .pi {
    color: #ccc;
  }

.p-monthpicker-month {
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #ccc;
  }
  40%,
  70% {
    stroke: var(--primary);
  }
}

body .p-datepicker .p-datepicker-header .p-datepicker-title {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title span {
    margin-right: 10px;
  }

.p-autocomplete {
  display: flex;
}

@media (max-width: 768px) {

.split-autocomplete {
    width: 100px
}

    .split-autocomplete input {
      width: 80%;
    }
  }

.shiftForm-container {
  width: 658px;
  padding-left: 10px;
}

@media (max-width: 768px) {

.shiftForm-container {
    height: inherit;
    width: inherit
}
  }

.p-growl {
  position: fixed;
  width: 30em;
}

@media (max-width: 480px) {

.p-growl {
    width: 18em
}
    .p-growl p {
      font-size: 13px;
    }
  }

@media (max-width: 480px) {

body .p-growl .p-growl-item-container .p-growl-item .p-growl-image {
    font-size: 1.8em
}
  }

@media (max-width: 480px) {

.p-growl-title {
    font-size: 13px
}
  }

.p-card-body {
  padding: 0;
}

.p-card {
  background-color: #ffffff;
  color: #000000;
  width: auto;
  height: auto;
  margin: 0;
}

.p-card-content {
  margin: 0px 100px 0px 100px;
}

@media (max-width: 480px) {

.p-card-content {
    margin: 0px 10px 0px 10px
}
  }

.p-card-header {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 480px) {

.p-card-header {
    padding: 5px
}
  }

.p-card-title {
  padding: 20px 0px 0px 110px;
  font-size: 26px;
}

@media (max-width: 480px) {

.p-card-title {
    font-size: 20px;
    padding: 10px 0px 10px 20px
}
  }

body .p-panel .p-panel-titlebar {
  border: none;
  padding-left: 20px;
  padding-bottom: 0;
  background-color: #ffffff;
  color: #333333;
  font-size: 18px;
}

@media (max-width: 480px) {

body .p-panel .p-panel-titlebar {
    font-size: 14px
}
  }

body .p-panel .p-panel-content {
  border: none;
  background-color: #ffffff;
  color: #333333;
  padding-bottom: 10px;
}

@media (max-width: 480px) {

body .p-panel .p-panel-content {
    font-size: 12px
}
  }

.p-card-footer {
  background-color: grey;
  color: #ffffff;
}

/* .fc-widget-header {
  background-color: #f03a6c !important;
}
.fc-widget-header span {
  color: #fff;
  font-weight: normal;
} */

body .fc .fc-row {
  border-right: none;
  text-align: center;
}

.fc-row {
  max-height: 100px;
}

.fc-time {
  display: none;
}

.rct-item {
  padding: 0 5px;
}

.react-calendar-timeline {
  border-right: 1px solid var(--borderColor);
  border-left: 1px solid var(--borderColor);
  border-top: 1px solid var(--borderColor);
}

.react-calendar-timeline-container {
    min-height: calc(100vh - 390px);
  }

.react-calendar-timeline .rct-header-root {
  background-color: #f4f4f4;
}

.react-calendar-timeline .rct-sidebar {
  border-right: none;
}

.react-calendar-timeline .rct-dateHeader-primary {
  color: #000;
}

.react-calendar-timeline .rct-calendar-header {
  border: none;
}

.react-calendar-timeline .rct-dateHeader {
  border-left-width: 1px;
  border-bottom: none;
}

.rct-dateHeader span {
    font-size: 12px;
  }

.rct-calendar-header > div:nth-child(1) {
  display: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-width: 1px;
}

.rct-scroll {
  cursor: default !important;
  overflow: hidden !important;
}

.static-container {
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.offline-container {
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.offline-container h1 {
    color: var(--primary);
    font-size: 32px;
  }

@media (max-width: 768px) {

.offline-container h1 {
      font-size: 18px
  }
    }

.content {
  font-size: 18px;
  margin: 0 20px;
}

@media (max-width: 768px) {

.content {
    font-size: 14px
}
  }

.static-title {
  font-size: 100px;
  color: var(--primary);
  line-height: 80px;
}

@media (max-width: 768px) {

.static-title {
    font-size: 32px
}
  }

.static-content {
  font-size: 24px;
  padding: 30px;
}

@media (max-width: 768px) {

.static-content {
    font-size: 20px
}
  }

.static-button {
  padding: 5px;
  font-size: 18px;
}

.workTendency .tr .th {
  font-weight: normal !important;
  padding: 8px !important;
}

.workTendency .tr .td {
  padding: 8px !important;
}

.workTendency-th {
  width: 10% !important;
  margin-right: 5% !important;
}

/* .ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.85) !important;
  cursor: not-allowed;
} */

.dateFilter {
  width: 170px !important;
  margin-right: 20px !important;
  padding: 0.429em !important;
  border: 1px solid #a6a6a6 !important;
}

.dateFilter:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

.dateFilter:hover {
  border-color: #212121 !important;
}

.note::after {
  content: '※';
  color: #f03a6c;
  font-weight: bold;
}

div[rootclassname="mx-auto"] {
  margin: 0 auto;
}

.downloadDaily{
  margin-top: 50px ;
  margin-right: 1rem ;
  display: flex ;
  justify-content: end ;
}

:root {
  --primary: #f03a6c;
  --boxShadow: 2px 0 2px rgba(0, 0, 0, 0.1);
  --mainBg: #fffdfe;
  --red: #f84e51;
  --redBg: #f9b7b8;
  --green: #34a835;
  --yellow: #ffba01;
  --borderColor: #c8c8c8;
  --gray: #818c92;
  --warning: #daefff;
  --thColor: #dadee0;
  --black: #0f1111;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1400px;
}

.container.is-mini {
    max-width: 300px;
  }

.container.is-medium {
    max-width: calc(100vw - 25px);
  }

.container.is-max {
    max-width: 1600px;
  }

@media (max-width: 768px) {

.container {
    border: 1px solid #c2c6c7;
    background-color: #fff;
    border-radius: 5px
}
  }

@media (max-width: 1800px) {
  .container.container-transport-report {
    max-width: 64%;
  }

  .container.container-work-report {
    max-width: 64%;
  }
  .doctor-portal {
    max-width: 80%;
    margin: auto;
  }
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
    
  }
  
    
  
    .container.is-max-portal {
      max-width: 80%;
    }
}

@media (max-width: 1024px) {
  .container.container-transport-report {
    max-width: 100%;
  }

  .container.container-work-report {
    max-width: 100%;
  }
  .doctor-portal {
    max-width: 100%;
  }
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
    
  }
  
    
  
    .container.is-max-portal {
      max-width: 100%;
    }
}

.doctor-layout-body .container {
    padding: 20px;
  }

@media (max-width: 768px) {

.doctor-layout-body {
    margin: 0px 5px 5px 5px;
    width: 100%
}
  }

.row {
  display: flex;
}

@media (max-width: 768px) {

.row {
    flex-wrap: wrap
}
  }

.col-3 {
  max-width: 25%;
}

.col-6 {
  width: 50%;
}

.col-6+.col-6 {
    margin-left: 20px;
  }

@media (max-width: 768px) {

.col-6+.col-6 {
      margin-left: 0;
      margin-top: 20px
  }
    }

@media (max-width: 768px) {

.col-6 {
    width: 100%
}
  }

.col-8 {
  width: 80%;
}

.col-8+.col-8 {
    margin-left: 20px;
  }

@media (max-width: 768px) {

.col-8+.col-8 {
      margin-left: 0;
      margin-top: 20px
  }
    }

@media (max-width: 768px) {

.col-8 {
    width: 100%
}
  }

.col-9 {
  width: 90%;
}

.col-9+.col-9 {
    margin-left: 20px;
  }

@media (max-width: 768px) {

.col-9+.col-9 {
      margin-left: 0;
      margin-top: 20px
  }
    }

@media (max-width: 768px) {

.col-9 {
    width: 100%
}
  }

.breadcrumb {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: var(--gray);
}

.breadcrumb i {
    color: var(--gray);
    padding: 0 5px;
  }

.breadcrumb a {
    color: #000;
  }

.modal-container {
  padding: 20px;
  max-height: 90vh;
  position: relative;
  min-width: auto;
  width: 51vw;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

@media (max-width: 768px) {

.modal-container {
    min-width: auto;
    width: 90vw
}
  }

.modal-container-custom {
  padding: 20px;
  max-height: 90vh;
  position: relative;
  min-width: auto;
  width: 40vw;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

@media (max-width: 768px) {

.modal-container-custom {
    min-width: auto;
    width: 90vw
}
  }

.small-modal-container {
  padding: 20px;
  max-height: 90vh;
  position: relative;
  min-width: auto;
  width: 20vw;
  min-width: 550px;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

@media (max-width: 768px) {

.small-modal-container {
    min-width: auto;
    width: 90vw;
    min-width: unset
}
  }

.modal-content {
  z-index: 0;
  overflow-y: auto;
  max-height: calc(90vh - 50px);
  padding-top: 50px;
}

.modal-content-custom {
  z-index: 0;
  overflow-y: auto;
  max-height: calc(90vh - 50px);
}

.modal-top {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}

.modal-title {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  padding-bottom: 5px;
  background-color: #fff;
  text-align: center;
  z-index: 1;
}

.modal-bottom {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-bottom button+button,
  .modal-bottom a+button,
  .modal-bottom a+a,
  .modal-bottom button+a {
    margin-left: 20px;
  }

.background-color-rows-duplicate {
  background-color: var(--thColor);
}

.background-grey {
  background-color: #d8d9da !important;
  color: rgb(24, 22, 22) !important;
}

.background-grey span {
  background-color: #d8d9da !important;
  color: rgb(24, 22, 22) !important;
}

.background-grey a {
  background-color: #d8d9da !important;
  color: rgb(24, 22, 22) !important;
}

.background-pink {
  background-color: #fff1f6 !important;
}

.modal-table {
  margin-left: auto;
  margin-right: auto;
}

.modal-table th,
  .modal-table td {
    padding: 10px;
  }

.modal-table th {
    text-align: left;
  }

@media (max-width: 768px) {

.modal-table {
    margin: 0px
}

    .modal-table tr {
      display: block;
      margin-bottom: 10px;
    }

    .modal-table th {
      display: block;
      width: 100%;
      padding: 0;
    }

    .modal-table td {
      display: block;
      width: 100%;
      margin-left: 10%;
      border: none;
      padding: 5px;
    }
  }

.table-moving-expenses {
  border-spacing: 0;
  border-right: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.table-moving-expenses th,
  .table-moving-expenses td {
    padding: 10px;
    border-left: 1px solid var(--borderColor);
    border-top: 1px solid var(--borderColor);
    text-align: center;
  }

.table-moving-expenses th {
    font-size: 13px;
    background-color: var(--thColor);
  }

.table-moving-expenses td.center {
    text-align: center;
  }

.table-moving-expenses td .action {
      display: flex;
      justify-content: space-around;
    }

.table-moving-expenses-registration {
  border-spacing: 0;
  border-right: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.table-moving-expenses-registration th,
  .table-moving-expenses-registration td {
    padding: 10px;
    border-left: 1px solid var(--borderColor);
    border-top: 1px solid var(--borderColor);
    text-align: center;
  }

.table-moving-expenses-registration th {
    font-size: 13px;
    background-color: #f4f4f4;
  }

.table-moving-expenses-registration td.center {
    text-align: center;
  }

.table-moving-expenses-registration td .action {
      display: flex;
      justify-content: space-around;
    }

.modal-wrapper {
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
}

.modal-wrapper>.p-dialog:nth-child(1)>.p-dialog-titlebar>.p-dialog-titlebar-icons {
  float: none;
  text-align: right;
}

.modal-wrapper>.p-dialog:nth-child(1)>.p-dialog-footer {
  text-align: center;
}

.th-image {
  margin-left: -30px;
  width: 40%;
  padding: 15px;
  font-weight: bold;
  white-space: normal;
}

.td-image {
  width: 55%;
  padding: 15px;
  flex-grow: 1;
  position: relative;
}

.overflow-container {
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.report-title {
    display: none;
  }

@media (max-width: 768px) {

.report-title {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      background-color: #f4f4f4;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 9px;
      border-color: #979797;
      border-style: solid;
      border-width: 1px 0 0 0;
      align-items: center;

      position: relative
  }
    }

.report-open {
    display: none;
  }

@media (max-width: 768px) {

.report-open {
      display: flex;
      flex-flow: column;
      max-height: 300px
  }
    }

.report-close {
    max-height: 0px;
    display: none;
  }

.report-item {
    margin-block-start: 5px;
    margin-block-end: 5px;
    font-size: 12px;
  }

.report-bold {
    font-size: 13px;
    font-weight: bold;
  }

.down-arrow,
.up-arrow {
  width: 11px;
  position: absolute;
  right: 16px;
}

.up-arrow {
  transform: scale(1, -1);
}

.icon-btn-moving {
  margin-left: -12px;
}

.flex-parent {
  display: flex;
  flex-wrap: wrap;
}

.flex-parent .flex-item {
    width: 50%;
  }

@media (max-width: 768px) {

.flex-parent .flex-item {
      width: 100%
  }
    }

.flex-parent.is-center {
    justify-content: center;
  }

.box {
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {

.box {
    border-radius: 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    margin: 10px 0px
}
  }

.box-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.is-past .progress-container::before {
    display: none !important;
  }

.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

.progress-container.is-got::before {
        content: '';
        position: absolute;
        top: -8px;
        left: -10px;
        width: calc(100% + 22px);
        height: calc(100% + 4px);
        background-color: var(--redBg);
        z-index: 0;
      }

.progress-container span {
      white-space: nowrap;
    }

.progress-wrapper {
    position: relative;
    z-index: 1;
    text-align: center;
  }

.progress-bar {
    width: 60px;
    height: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 3px #666666;
  }

.progress-bar-bg {
      width: 50px;
      height: 15px;
      background-color: var(--redBg);
    }

.progress-bar.danger {
      border-color: red;
      background-color: red;
    }

.progress-bar.warning {
      border-color: orange;
      background-color: orange;
    }

.progress-bar.info {
      border-color: yellow;
      background-color: yellow;
    }

.progress-bar.success {
      border-color: green;
      background-color: green;
    }

.progress-content {
    border-radius: 4px;
    height: 8px;
    background-color: #fff;
  }

.is-sp {
  display: none;
}

@media (max-width: 768px) {

.is-sp {
    display: block
}
  }

.is-pc {
  display: block;
}

@media (max-width: 768px) {

.is-pc {
    display: none
}
  }

.table-container {
  padding: 15px;
}

th a {
    color: #000;
  }

.table-wrapper {
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.table-wrapper td.saturday {
    background-color: rgba(181, 227, 243, 0.2) !important;
  }

.table-wrapper td.sunday {
    background-color: rgba(236, 31, 38, 0.1) !important;
  }

.table-wrapper th {
    background-color: var(--thColor) !important;
    border-left: none !important;
    border-top: none !important;
  }

.table-wrapper tr td:first-child {
      border-left: none !important;
    }

.table-wrapper tr td:last-child {
      border-right: none !important;
    }

.table-wrapper tr:last-child td {
      border-bottom: none !important;
    }

.table {
  border-spacing: 0;
  border-right: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.table th,
  .table td {
    padding: 10px;
    border-left: 1px solid var(--borderColor);
    border-top: 1px solid var(--borderColor);
  }

.table th {
    background-color: var(--thColor);
  }

.table td.center {
    text-align: center;
  }

.table td .action {
      display: flex;
      justify-content: space-around;
    }

.table td .action-doctor-list {
      display: flex;
      justify-content: space-around;
    }

.table-block td {
    background-color: #e6e6e6;
  }

.table-moving-blue {
  color: #8cc0fa;
}

.table-moving-red {
  color: #ff4d8e;
}

.table-workSchedule-cancel {
  border-spacing: 0;
  border-right: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.table-workSchedule-cancel th,
  .table-workSchedule-cancel td {
    padding: 15px;
    border-left: 1px solid var(--borderColor);
    border-top: 1px solid var(--borderColor);
  }

.table-workSchedule-cancel th {
    padding: 20px;
    font-size: 13px;
    background-color: var(--thColorCancel);
  }

.table-workSchedule-cancel td.center {
    text-align: center;
  }

.table-workSchedule-cancel td .action {
      display: flex;
      justify-content: space-around;
    }

.table-workSchedule-cancel-td-even {
  text-align: center;
  background-color: #fff1f6;
}

.table-workSchedule-cancel-td-blue {
  color: #8cc0fa;
}

.table-workSchedule-cancel-td-red {
  color: #ff4d8e;
}

.table-workSchedule-cancel-td-odd {
  text-align: center;
}

.table-workSchedule-cancel-th {
  background-color: #dadee0;
}

.table-workSchedule {
  border-spacing: 0;
  border-right: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.table-workSchedule th,
  .table-workSchedule td {
    padding: 10px;
    border-left: 1px solid var(--borderColor);
    border-top: 1px solid var(--borderColor);
  }

.table-workSchedule th {
    font-size: 13px;
    background-color: var(--thColor);
  }

.table-workSchedule td.center {
    text-align: center;
  }

.table-workSchedule td .action {
      display: flex;
      justify-content: space-around;
    }

.table-action {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.table-action button {
    margin: 4px;
  }

.align-center td,
  .align-center th {
    text-align: center;
  }

.paginator-total {
  line-height: 34px;
  padding: 0 5px;
}

.schedule-calendar {
  width: 85%;
  margin: 0 auto;
}

@media (max-width: 768px) {

.schedule-calendar {
    width: 100%
}
  }

.transport {
  width: 95%;
  margin: 0 auto;
}

@media (max-width: 768px) {

.transport {
    width: 100%
}
  }

.accepted-true {
  color: #fff;
  background-color: green;
  padding: 10px;
  border-radius: 10px;
}

.accepted-false {
  color: #fff;
  background-color: orange;
  padding: 10px 20px;
  border-radius: 10px;
}

.tr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tr .th {
    width: 30%;
    padding: 15px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }

@media (max-width: 768px) {

.tr .th {
      width: 100%;
      text-align: left;
      padding: 10px
  }
    }

.tr .td {
    width: 70%;
    padding: 15px;
    flex-grow: 1;
    position: relative;
  }

@media (max-width: 768px) {

.tr .td {
      width: 100%;
      padding: 10px
  }
    }

.alert {
  /* position: absolute; */
  bottom: -5px;
  left: 20px;
  white-space: nowrap;
  color: var(--red);
  margin-top: 5px;
  font-size: 12px;
}

.alert.is-left {
    left: 0;
  }

.alert+.alert {
    bottom: -25px;
  }

@media (max-width: 768px) {

.alert {
    font-size: 11px
}
  }

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container+.checkbox-container {
    margin-top: 10px;
  }

.checkbox-label {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.staff-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.doctor-header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.staff-buttons button+button,
  .staff-buttons a+a,
  .staff-buttons button+a,
  .staff-buttons a+button {
    margin-left: 20px;
  }

.staff-title {
  line-height: 33px;
  font-size: 28px;
}

.doctor-title {
  line-height: 33px;
  font-size: 28px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 200px;
}

.dropzone img {
    max-width: 300px;
  }

.dropzone:hover {
    border-color: var(--green);
  }

.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

.status-badge {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
}

.status-badge+.status-badge {
    margin-left: 10px;
  }

.status-badge.is-success {
    background-color: var(--green);
  }

.status-badge.is-warning {
    background-color: var(--yellow);
  }

.status-badge.is-danger {
    background-color: var(--red);
  }

.status-badge.is-children {
    background-color: #fdab8f;
  }

.status-badge.is-internal {
    background-color: #9fd4e7;
  }

.status-badge i {
    color: #fff;
    font-size: 14px;
  }

.status-badge svg {
    fill: #fff;
    transform: scale(0.8);
  }

.status-label {
  font-size: 13px;
  white-space: nowrap;
}

.status-label.is-success {
    color: var(--green);
  }

.status-label.is-warning {
    color: var(--yellow);
  }

.tooltip-parent {
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.tooltip-parent span {
    padding: 0 5px;
  }

@media screen and (max-width: 480px) {

.tooltip-parent {
    font-size: 10px !important
}
  }

.new {
  display: flex;
  flex-direction: column;
}

.tooltip-icon {
  width: 40px;
  height: 50px;
  transform: scale(0.5);
}

.no-wrap {
  white-space: nowrap;
}

.slashed-item::after {
    content: ',\A';
    padding-right: 5px;
  }

.slashed-item:last-child::after {
      display: none;
    }

.menu-icon {
  transform: scale(0.8);
  transform-origin: center left;
  width: 20px;
}

.menu-icon path {
    fill: var(--primary);
  }

.search-parent {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.search-parent .spacer {
    flex-grow: 1;
  }

.text-nowrap {
  text-wrap: nowrap;
}

.search-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.search-item {
    margin-left: 10px;
  }

.statusTips {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}

.item-tips {
  display: inline-block;
  font-size: 11px;
}

.item-tips {
    margin-left: 10px;
  }

.staff-shift {
  height: 10px;
  border-radius: 8px;
  width: 20px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.staff-shift-secondexam {
    background-color: rgb(255,0,186);
  }

.staff-shift-empty {
    background-color: var(--red);
  }

.staff-shift-ordered {
    background-color: var(--warning);
  }

.staff-shift-accepted {
    background-color: #fff;
    border: 1px solid #000;
  }

.staff-shift-canceled {
    background-color: #818c92d0;
  }

.staff-shift-fixed {
    background-color: #ffffcc;
  }

.staff-shift-break {
    background-color: #007ad9;
  }

@media (max-width: 768px) {

iframe {
    width: 100%;
    height: 50vw
}
  }

.privacy-li {
  padding-bottom: 10px;
}

.privacy-p {
  padding-left: 30px;
}

.privacy-layout-company {
  text-align: right;
  font-weight: bold;
  margin-bottom: 100px;
  padding-right: 20px;
}

.privacy-layout-company p {
    margin: 0;
  }

@media (max-width: 768px) {

.privacy-layout-company {
    font-size: 12px
}
  }

.privacy-h2 {
  padding: 20px;
  font-size: 26px;
}

@media (max-width: 768px) {

.privacy-h2 {
    margin: 10px;
    font-size: 20px;
    padding: 5px
}
  }

.privacy-footer {
  display: flex;

  justify-content: space-around;
}

@media (max-width: 768px) {

.privacy-footer {
    padding: 0px;
    flex-direction: column
}
  }

.layout-logo-privacy-footer {
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 0;
  width: 180px;
}

@media (max-width: 768px) {

.layout-logo-privacy-footer {
    width: 28vw
}
  }

.layout-logo-tips-privacy-footer p {
    margin-top: 0px;
    width: 100%;
    font-size: 10px;
  }

@media (max-width: 768px) {

.layout-logo-tips-privacy-footer p {
      font-size: 8px;
      width: 100%
  }
    }

.privacy-layout-company-footer {
  text-align: left;
  font-weight: bold;
  padding: 20px;
}

.privacy-layout-company-footer p {
    margin: 0;
  }

@media (max-width: 768px) {

.privacy-layout-company-footer {
    padding-left: 20px;
    font-size: 10px
}
  }

.staff-download-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.staff-download-buttons a {
    margin-left: 20px;
  }

.staff-download-buttons-daily {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.staff-download-buttons-daily a {
    margin-left: 20px;
  }

.mobile-report-title {
  font-size: 14px;
  font-weight: bold;
  background-color: #f4f4f4;
  padding: 5px;
}

.mobile-report {
  border-color: #979797;
  border-style: solid;
  border-width: 1px;
  border-collapse: collapse;
}

.mobile-report-item {
  font-size: 13px;
  padding: 2px 0 2px 5px;
}

.mobile-report-item-content {
  padding-left: 10px;
  font-weight: bold;
}

.mobile-cart {
  padding-left: 5px;
}

.mobile-cart-item {
  font-size: 13px;
}

.mobile-cart-item-content {
  padding-left: 10px;
  font-weight: bold;
}

.recess-info {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: bold;
  color: var(--red);
  text-align: left;
}

@media (max-width: 768px) {

.recess-info {
    font-size: 11px
}
  }

.mobile-work-total {
  display: flex;
  margin: 10px;
}

.mobile-work-total-item {
  font-weight: bold;
  margin-left: 20px;
}

ol.terms-parenthesis {
  padding: 0 0 0 2em;
  margin: 0;
}

ol.terms-parenthesis li {
  list-style-type: none;
  list-style-position: inside;
  counter-increment: cnt;
}

ol.terms-parenthesis li:before {
  display: marker;
  content: '(' counter(cnt) ') ';
}

.staff-comment-container {
  /* padding: 20px;
  paddingtop: 10px; */
}

.required:after {
    content: '*';
    color: red;
  }

.onlyStaff:after {
    content: 'ONLY STAFF';
    color: #db7093;
    font-size: 5px;
    font-weight: lighter;
    border: solid 2px #db7093;
    border-radius: 20px;
    margin: 4px;
  }

.doctor-comment .p-accordion-header a {
      background-color: var(--primary) !important;
      color: #fff !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      font-size: 14px;
    }

.doctor-comment .p-accordion-header span {
      color: #fff !important;
    }

.doctor-comment .p-accordion-header:not(.p-disabled).p-highlight a {
      background-color: #dbdbdb !important;
      border: 1px solid #dbdbdb !important;
    }

.doctor-comment .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-text {
      color: #333333 !important;
    }

.doctor-comment .p-accordion-header:not(.p-disabled).p-highlight span {
      color: #333333 !important;
    }

.doctor-no-comment .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
    background-color: #dbdbdb !important;
    border: 1px solid #dbdbdb !important;
  }

.doctor-no-comment .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-header-text {
      color: #333333 !important;
    }

.doctor-no-comment .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a span {
      color: #333333 !important;
    }

.tr-checkboxUpdateProfile {
  padding-left: 50px;
}

.tranport button {
  height: auto !important;
}

.tranport .p-disabled {
  cursor: not-allowed !important;
}

.p-button-outlined {
  width: 86px;
  color: #ff0e6b !important;
  border-color: #ff0e6b !important;
  background: #fff !important;
}

.modal-bottom-right {
  display: flex;
  justify-content: right;
}

.modal-bottom-right button+button,
  .modal-bottom-right a+button,
  .modal-bottom-right a+a,
  .modal-bottom-right button+a {
    margin-left: 20px;
  }

.modal-antd .ant-btn:hover,
.modal-antd .ant-btn:focus {
  background-color: var(--gray) !important;
  color: #fff !important;
  border-color: transparent !important;
}

.modal-antd.ant-btn {
  background-color: #fff !important;
  color: var(--gray) !important;
  border-color: var(--gray) !important;
  transition: 0.4s ease-in-out !important;
}

.modal-antd .ant-modal-title {
  font-weight: bolder !important;
}

.color-url input {
  color: #40a9ff !important;
}

.tab-transport .ant-tabs-tab {
  background-color: #e7ebed !important;
  font-weight: bold;
  color: #383737;
  border: 1px solid #d9dee1 !important;
  overflow: none !important;
}

.transport .ant-tabs {
  overflow: visible;
}

.tab-transport {
  overflow: visible !important;
}

.tab-transport .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.tab-transport .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--primary) !important;
}

.ant-tabs-tab {
  margin-left: 0px !important;
}

.tab-transport .ant-tabs-tab-btn:hover {
  color: #f1377c;
}

.cancel-before .p-dialog-titlebar {
  padding-bottom: 29px !important;
}

.td-cancel {
  padding-left: 50px !important;
}

.unconfirm-shift {
  margin-left: -128px;
  float: left;
  margin-top: 12px;
  color: #f03a6c;
}

.text-red {
  margin-top: 12px;
}

.is-join-field .tr .th {
  white-space: normal;
}

.ant-pagination-total-text {
  float: left;
}

.ant-table-thead>tr>th {
  background-color: #dadee0 !important;
  font-weight: bold !important;
  border-right: 1px solid #c8c8c8 !important;
}

.document-exp {
  float: left;
  margin-top: 12px;
  color: #818c92;
}

.overtime {
  font-size: 12px;
  height: 45px;
  background: -webkit-linear-gradient(bottom, #eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overtime_fontsize {
  font-size: 14px !important;
  font-weight: bold;
}

.timepicker .ant-picker-ok {
  display: none;
}

.w-100 {
  width: 100%;
}

@media (max-width: 768px) {

.w-100 {
    width: 20%
}
  }

@media (max-width: 480px) {

.w-100 {
    width: 35%
}
  }

.ant-picker-input>input[disabled] {
  color: rgba(0, 0, 0, 0.85) !important;
}

.overtime_title {
  width: 28%;
  font-size: 14px;
  font-weight: bold;
}

.width-67 .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-checkbox-disabled+span {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85) !important;
}

.over-time-item__title {
  font-size: 14px;
  font-weight: bold;
  min-width: 35%;
}

.question-category {
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 5px;
  display: inline-block;
  color: #fff;
  background-color: #f03a6c;
}

.question-title {
  color: #f03a6c;
  margin-bottom: 6px;
}

.category-form-td {
  width: 100%;
  margin-left: 20px;
}

.input-category {
  height: 35px;
  border-radius: 5px;
}

.row-container {
  display: flex;
  justify-content: space-between;
}

.oneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.textInput-wrapper>.tr:not(.change-email)>.th,
.textInput-wrapper>.tr:not(.change-email)>.td {
  padding-bottom: 0;
}

.textInput-wrapper>input {
  padding-bottom: 0;
}

.textInput-wrapper>.tr.change-email>.th,
.textInput-wrapper>.tr.change-email>.td {
  padding-top: 0;
}

.mx-auto {
  margin: 0 auto !important;
}

.change-email-form .th {
  width: 35% !important;
}

.change-email-form .td {
  width: 65% !important;
}

.doctors .tr .th {
    width: auto !important;
  }

.doctors .tr .td {
    width: 0px !important;
  }

span.ant-radio+* {
  width: 80%;
}

.outline-red:enabled:hover {
  background-color: #f03a6c !important;
  color: #fff !important;
  border-color: transparent;
}

.reflect-button:enabled:hover {
  background-color: #da4a70 !important;
  color: #fff !important;
  border-color: transparent;
}

.col-7 {
  width: 70%;
}

@media (max-width: 768px) {

.col-7 {
    width: 100%
}
  }

.col-5 {
  width: 50%;
  float: left;
}

.recruitment_title {
  padding: 10px;
  background-color: var(--primary) !important;
  color: #fff !important;
  /* border-radius: 5px; */
  font-size: 16px;
  font-weight: bold;
}

.recruitment_subtitle {
  font-weight: 500;

}

.timepicker .ant-picker-ok {
  display: none;
}

.p-dialog .p-dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}

.recruitment-content {
  font-size: 18px;
  margin: 0px;
  color: rgba(159, 149, 149, 0.85);
  display: block;
  display: -webkit-box;
  height: 16px * 1.3 * 3;
  font-size: 16px;
  line-height: 1.3;
  -webkit-line-clamp: 4;
  /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slick-slide:not(.slick-active){
  height: 0 !important;
}

.dropzone {
  height: 30vh;
}

.dropzone img {
  max-height: calc(30vh - 10px);
}

.preview-img-dialog .p-dialog::after {
  opacity: 0.05;
}

.preview-img-dialog .p-dialog {
  box-shadow: none;
}

.justify-bettween {
  justify-content: space-between;
}

.padding-0{
  padding: 0 !important;
}

.border-top_none {
  border-top: none !important;
}

.checkbox-color-border .ant-checkbox-inner {   
  border: 2px solid red;
}