.calendar-container {
  position: relative;
}

.fc-today {
  background: none !important;
}
.calendar-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 3.5rem;
}
.calendar-control-incentive {
  margin-bottom: 10px;
  margin-left: 8%;
  margin-top: 10px;
  width: 84%;
  display: flex;
  justify-content: space-between;
}
.calendar-title {
  font-size: 20px !important;
  line-height: 33px;
  font-weight: bold;
}
.available-shift-container .ant-tabs-tab-btn:hover {
  color: var(--black) !important;

}
.doctor-container {
  display: block;
  justify-content: space-between;
  align-items: stretch;
  @media (--tb) {
    flex-direction: column;
  }

}
.show {
  &.doctor-left {
    width: 100%;

    @media (--tb) {
      width: 100%;
      overflow: hidden;
      padding: 0;
    }
  }
  &.doctor-content {
    width: 100%;
    padding: 0rem 5rem 5rem;
    opacity: 1;

    @media (--tb) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.doctor-left {
  width: 100%;
  transition: width 0.4s linear;
  padding: 3rem 1.5rem;

  @media (--tb) {
    width: 100%;
    transition: none;
    padding: 3px;
  }
}
.doctor-content {
  width: 0;
  transition: opacity 0.4s ease-in-out;
  overflow: hidden;
  opacity: 0;
  padding: 3px;

  @media (--tb) {
    transition: none;
  }

  &-inner {
  }

  .box {
    height: 100%;
    @media (--tb) {
      height: auto;
    }
  }
  .boxIe {
    height: 1000px;
    @media (--tb) {
      height: auto;
    }
  }

  h2 {
    margin: 0;
    line-height: 33px;
    @media (--sp) {
      font-size: 17px;
    }
  }

  .content-table {
    th {
      @media (--sp) {
        font-size: 12px;
      }
    }
  }

  .content-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  @media (--sp) {
    width: 100%;
    font-size: 0.9em;
  }
}

.fc-event {
  border-radius: 0 !important;
}

.fc-toolbar h2 {
  font-size: 20px !important;
  line-height: 33px;
}
.fc-past {
  opacity: 0.8;
}
.fc-past .fc-day-number {
  color: #666;
}

.fc-widget-header .fc-sun {
  color: #ef5956;
  font-weight: bold;
}
.fc-sun .fc-day-number {
  color: #ef5956;
  font-weight: bold;
}
.fc-widget-header .fc-sat {
  color: #4969f8;
  font-weight: bold;
}
.fc-sat .fc-day-number {
  color: #4969f8;
  font-weight: bold;
}

.fc-sun {
  background-color: rgba(236, 31, 38, 0.1);
}

.fc-sat {
  background-color: rgba(181, 227, 243, 0.2);
}

.fc-day-number {
  font-size: 12px !important;
  text-align: center !important;
  @media (var --sp) {
    font-size: 10px !important;
    text-align: center !important;
  }
}

.event .fc-time {
  display: none;
}
.event .fc-title {
  display: none;
}

.holiday .fc-title {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #acceda;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
}

.holiday .fc-time {
  display: none;
}

.holiday {
  pointer: default;
  pointer-events: none;
  @media (--sp) {
    touch-action: unset;
  }
}

.tag-name-new,
.tag-name-update {
  position: absolute;
  top: 0;
}

.tag-name-new .fc-title {
  display: flex;
  justify-content: center;
  width: 50px;
  color: #fff;
  background-color: #ff2575;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 25px;
}

.tag-name-new .fc-time {
  display: none;
}

.tag-name-new {
  pointer: default;
  pointer-events: none;
  @media (--sp) {
    touch-action: unset;
  }
}

.tag-name-update .fc-title {
  display: flex;
  justify-content: center;
  width: 50px;
  color: #fff;
  background-color: #ff9a23;
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 25px;
}

.tag-name-update .fc-time {
  display: none;
}

.tag-name-update {
  pointer: default;
  pointer-events: none;
  @media (--sp) {
    touch-action: unset;
  }
}

.tag-name-special-salary {
  pointer: default;
  pointer-events: none;
  @media (--sp) {
    touch-action: unset;
  }
}

.tag-name-special-salary .fc-time {
  display: none;
}

.tag-name-special-salary .fc-title {
  display: flex;
  color: #fff;
  background-color: #ff2575;
  font-size: 11px;
  font-weight: bold;
  justify-content: center;
}
.tag-name-special-salary-mobile .fc-title{
  font-size: 9px !important;
}

.event {
  width: 100%;
  height: auto;
  min-height: 30px;
  margin: 0;
  cursor: pointer;
  padding: 0 !important;
  box-sizing: border-box;
  :relative ;
  border: none !important;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    box-sizing: border-box;
    @media (--sp) {
      width: 14px;
      height: 14px;
      min-width: 14px;
    }
    /* border: 4px solid var(--green) !important; */
  }
}
.event-full {
  &::after {
    border: 4px solid var(--yellow) !important;
  }
}
.event-only-as {
  &::after {
    border: 4px solid var(--green) !important;
    @media (--sp) {
      border: 3px solid var(--green) !important;
    }
  }
}
.event-only-accepted {
  &::after {
    background-color: var(--primary) !important;
  }
}
.event-only-not-accepted {
  font-size: 0em;
  &::after {
    background-color: var(--yellow) !important;
  }
}

.event-selected {
  /* background-color: #fcf8e3 !important; */

  &::before {
    content: '';
    position: absolute;
    top: -21px;
    left: 0;
    width: 100%;
    height: calc(100% + 28px);
    @media (--lg) {
      height: calc(100% + 23px);
    }
    @media (--sp) {
      height: calc(100% + 23px);
    }
    /* background-color: #fcf8e3; */
    z-index: -1;
  }
}

.p-dropdown-panel {
  top: 35px !important;
}

.detail-view {
  border: 1px solid var(--primary);
  border-radius: 10px;
  overflow: hidden;

  th {
    background-color: #f8f8f8 !important;
  }

  i {
    color: var(--primary);
  }
}

.tool-event {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    box-sizing: border-box;
    @media (--sp) {
      width: 25px;
      height: 25px;
      min-width: 25px;
    }
  }
}
/* border: 4px solid var(--green) !important; */
.mobile-accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;

  .active,
  .accordion:hover {
    background-color: #ccc;
  }
}

.mobile-header {
  display: flex;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
}

.mobile-accordian-body {
  background-color: white;
  display: none;
  overflow: hidden;
}

.mobile-work-schedule-item {
  position: relative;
  padding: 5px;
  border: 1px solid var(--gray);
  border-width: 0 1px 1px 1px;
  .clinic-name {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: bold;
    /* color: blue;
    text-decoration: underline; */
  }
  .action {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

tr:first-child > td > .fc-day-grid-event {
  margin-top: 0px;
}

.action-group {
  display: flex;
  justify-content: center;
}

.single-available-shift {
  width: 100%;
}

.single-available-shift th {
  text-align: right !important;
}

.alert-login {
  /* padding: 0 10%; */
  color: #db7093 !important;
}
.alert-login .modal-bottom {
  justify-content: center !important;
}

.alert-login .modal-title {
  color: #465564 !important;
  font-weight: bold !important;
}

.specify-start-label{
  padding-left: 0px;
}