.erorr-monthly-repeat {
  text-align: center;
  width: 75%;
}
.toggle-container .ant-switch-checked {
  background-color: #f03a6c;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #000000d9;
}
.editor-responsive .ql-formats{
  margin-right:0px !important;
}
.editor-responsive .ql-snow.ql-toolbar button{
  margin-left: 0px;
}