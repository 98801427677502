.create-waiting-recruit {
  .th {
    width: 20% !important;
  }
  
  .td {
    width: 80% !important;
  }

  .tr {
    align-items: start;
  }
  
  .workingHour-start {
    width: 30% !important;
  }
  
  .workingHour-end {
    width: 70% !important;
  }
  
  .tr-wrap {
    margin-left: 14px;
    margin-right: 14px;
  }
  
  .tr-note {
    margin-top: 2rem;
  }
  
  .button-row {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    button {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }
  }
  
  .ant-picker,
  .ant-select-multiple .ant-select-selector {
    border-color: #a6a6a6 !important;
  }
  
  .ant-select-multiple .ant-select-selector {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  
  .ant-select-selection-item {
    background: #f03a6c !important;
    color: #fff !important;
    border-radius: 4px !important;
  }
  
  .ant-select-multiple .ant-select-selection-item-content {
    margin-right: 1rem !important;
  }
  
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #fff !important;
  }
  
  .btn-main-color {
    color: #fff !important;
    background-color: #f03a6c !important;
    border-color: #f03a6c !important;
    :hover {
      background-color: #fff !important;
      color: #f03a6c !important;
    }
  }
  
  .btn-main-outline-color {
    color: #f03a6c !important;
    border-color: #f03a6c !important;
    :hover{
      background-color: #f03a6c !important;
      color: #fff !important;
    }
  }
  
  .workingHour-start, .workingHour-end {
    width: 200px !important;
    flex-grow: unset !important;
  }
  
  .error {
    color: #f03a6c;
  }

  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .date-picker {
    display: flex;
  }
}

.small-modal-content {
  padding-top: 0;
}