.job-search-box{
  color: var(--black);
  box-shadow: 0 4px 8px 0 #eeeeee, 0 6px 20px 0 #eeeeee;
  margin-bottom: 12px;
}
.job-search-box .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-nav-more {
  display: none !important;
}

@media only screen and (max-width: 1199px) {
  .job-search-box .ant-tabs-nav-wrap {
    border-right: 2px solid #c8c8c8 !important;
    border-radius: 5px 5px 0 0 !important;
  }
}

.job-search-box .ant-tabs-nav-wrap {
  border-radius: 5px 5px 0 0 !important;
}

.job-search-box .ant-tabs-tab {
  display: flex;
  justify-content: center;
  padding-bottom: 0px !important;
  width: 50%;
  border-radius: 5px 5px 0 0 !important;
  color: #8f8989 !important;
}

.job-search-box>.ant-tabs-tab-btn {
  color: #8f8989 !important;
}

.job-search-box .ant-tabs-tab:not(.ant-tabs-tab.ant-tabs-tab-active) {
  border: 0.3px solid #c8c8c8 !important;
  font-size: 16px;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  font-size: 16px;
  font-weight: 16px;
  border: 0.3px solid #c8c8c8;
  border-bottom: 0px;
}


.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active>.ant-tabs-tab-btn {
  color: var(--black) !important;
  border-bottom: 3px solid #f03a6c !important;
}


.job-search-box .ant-tabs-tab-btn,
.job-search-box .ant-tabs-tab-btn:hover {
  color: #8f8989 !important;
  padding: 15px 20px !important;

}

.btn-search .p-button,
.btn-search .p-button:hover {
  cursor: pointer;
  background-color: #f03a6c !important;
  color: white;
  border: none;
  display: block;
  margin: 0 auto;
}

.input-search-box,
.job-search-box .p-calendar {
  margin: 0 0 1rem;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.job-search-box .ant-tabs-tab-btn,
.job-search-box .title {
  font-weight: bold;
}

.ant-tabs-content-holder {
  background-color: #FFFFFF;
  padding: 1rem;
  border: 0.3px solid #c8c8c8 !important;
  border-top: none !important;
}

.job-search-box .ant-tabs {
  overflow: unset;
}

.job-search-box .p-dropdown,
.job-search-box .p-calendar {
  width: 100%;
}

.job-search-box .p-calendar input {
  width: 80%;
}

.job-search-box .p-calendar button {
  width: 20% !important;
}

.job-search-box .p-autocomplete .p-autocomplete-dd-input {
  border-right: none;
}

.job-search-box .p-autocomplete .p-autocomplete-dropdown {
  height: unset;
}

@media screen and (max-width: 320px) {


  .job-search-box .ant-tabs-tab-btn,
  .job-search-box .ant-tabs-tab-btn:hover {
    color: #8f8989 !important;
    padding: 10px 10px !important;

  }

}