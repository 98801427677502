.waiting-recruit {
  min-width: 900px;
  .main-color {
    background-color: var(--primary) !important;
    color: #fff !important;
    border-color: #fff !important;
  }
  
  .filter {
    margin: 20px 10px;
    display: flex;
    .date-picker {
      border-color: #a6a6a6 !important;
      padding-top: 5px !important;
      padding-bottom: 5.5px !important;
      border-radius: 3px !important;
    }
    &-doctorName {
      max-width: 200px;
    }

    .dropdown-clinic {
      min-width: 220px;
    }
  }
  
  .mx-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  
}