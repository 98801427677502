.doctor-waiting-recruit {
  width: 70%;
  margin: auto;
  min-width: 700px;
  .ml-5 {
    margin-left: 2rem !important;
  }
  
  .mx-2 {
    margin-left: 16px;
    margin-right: 16px;
  }
  
  .register-button {
    position: absolute;
    top: 20px;
    right: 100px;
  }
  
  .filter {
    display: flex;
    margin-bottom: 20px;
    .date-picker {
      min-width: 200px;
    }
  }
  
  .staff-header {
    padding-top: 20px;
    position: relative;
  }
  
  .one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  
}