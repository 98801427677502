.doctor-portal {
  padding: 1rem;
  .box {
    padding: 1rem;
  }

  .staff-header {
    padding-top: 20px;
    margin-bottom: 0px;
  }

  .header {
    display: flex;
    margin-bottom: 20px;
    position: relative;
    padding: 0 0 2rem;

    &-clear {
      margin-left: 20px !important;
    }

    &-contentInput {
      margin-left: auto;
      margin-right: 10px;
      position: relative;

      i {
        position: absolute;
        right: 9px;
        top: 9px;
        opacity: 0.5;
      }
    }
  }

  #item-order-1 {
    order: 1;
    width: 250px;
    position: absolute;
    left: 0%;
    margin: 0.5rem 0;
  }

  #item-order-2 {
    order: 2;
    position: absolute;
    left: 240px;
    top: 8px;
    height: 36px;
    width: 36px;
  }

  #item-order-3 {
    order: 3;
    position: absolute;
    width: 250px;
    right: 83px;
    margin: 0.5rem;
  }

  #item-order-4 {
    order: 4;
    position: absolute;
    right: 0%;
    width: 80px;
    margin: 0.5rem 0;
    height: 36px;
    background-color: var(--primary);
    color: #fff;
    border: none;
  }

  .body {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow-y: auto;

    &-menu {
      width: 25%;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      padding: 10px 20px;
      height: 550px;
      overflow-y: auto;
    }

    &-content {
      width: 75%;
      padding: 10px 20px;
      height: 550px;
    }
  }

  .body-menu {
    ul {
      list-style-type: none;
      padding-left: 25px;
      cursor: pointer;
      li {
        cursor: pointer;
      }
    }

    .node {
      line-height: 28px;
    }

    .node-lv1 {
      font-weight: bold;
      color: #f03a6c;
      font-size: 20px;
    }

    .node-lv2 {
      color: #f03a6c;
      font-size: 16px;
    }

    .node-lv3 {
      cursor: pointer;
      font-size: 16px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .nodeSearch {
    list-style-type: none;
    padding-left: 0;
  }

  .mark {
    color: #fff;
    background-color: #f03a6c;
  }

  .body-content {
    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .doctor-portal {
    .body-menu {
      width: 250px;
      overflow: auto;
    }

    .header {
      display: grid;
      flex-flow: column;
      padding: 0;
      margin-bottom: 10px;
    }

    .header-submit-btn {
      width: 100%;
      margin: 0.5rem auto;
    }

    #item-order-1 {
      order: 1;
      width: 70%;
      position: relative;
    }

    #item-order-2 {
      order: 2;
      top: 2px;
      position: relative;
      left: unset;
      margin: 0 0 0 0.5rem !important;
    }

    #item-order-3 {
      order: 3;
      width: 100%;
      position: relative;
      right: 0px;
      margin: 0;

      i {
        top: 0px;
      }

      input {
        width: 70%;
      }
    }

    #item-order-4 {
      order: 4;
      width: fit-content;
      position: relative;
      margin-left: 8px;
    }
  }

  .container {
    min-width: 650px;
  }
}

.body .p-accordion .p-accordion-header a {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0;
  justify-content: space-between;
}
.body-content {
  overflow: scroll;
}
.node-children {
  margin: 10px 0px;
}
.node-chidlren-link {
  color: #0f1111;
  text-decoration: underline !important;
  padding: 10px 20px;
  font-size: 16px;
}
.node-chidlren-link:hover {
  color: #0f1111;
}

@media (max-width: 500px) {
  .doctor-portal {
    .body-menu {
      width: 200px;
    }
  }
}
.body-mobile {
  width: 100%;
  .body-mobile-menu {
    width: 100%;
  }
}
// .ant-modal-content {
//   border-radius: 10px;
//   overflow: scroll;
// }
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.2);
}
.node-btn {
  background: none;
  border: none;
  cursor: pointer;
}
.doctor-portal span.p-accordion-toggle-icon {
  color: var(--black) !important;
}
.doctor-portal
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  a
  span.p-accordion-toggle-icon {
  color: var(--black) !important;
}
.body-mobile-menu .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}
.body-mobile-menu .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
.body-mobile-menu .ant-collapse {
  background: none;
}
.body-mobile-menu .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0px;
}
.body-mobile-menu
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: var(--black);
  font-weight: 600;
}
.body-mobile-menu .ant-collapse-header {
  position: relative;
  display: flex;
  align-items: center;
}
.body-mobile-menu
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  position: absolute;
  right: 0;
  font-size: 16px;
}
.body-mobile-menu .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
