.available-shift-container.container {
  width: 70%;
}

.content-header {
  padding: 2rem 1rem 1rem;
}

.available-shift-container h3,
.available-shift-container h2 {
  font-weight: bold;
}

.p-tabview-nav li {
  width: 30%;
}

.content-available-shifts .p-tabview-nav li a {
  padding: 0.5rem;
  width: 100%;
  background-color: rgb(26, 211, 66);
  text-align: center;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.p-tabview-nav li a {
  padding: 0.5rem;
  width: 100%;
  background-color: white;
}

.content-available-shifts .p-tabview-panels {
  border: solid 1px rgb(151, 148, 148) !important;
  border-radius: 3px;
  padding: 0 !important;
}

.content-available-shifts p {
  font-weight: bold;
}

.content-container:not(:first-of-type) {
  border-top: solid 1px rgb(151, 148, 148);
}

.content-container {
  padding: 1rem;
  margin: 0 0.25rem;
}

.content-row {
  display: flex;
  align-items: center;
}

.clinic-container .content-row {
  display: inline-block;
  width: 100%;
}

.content-container label {
  margin: 0 0.25rem;
}

.content-container.calendar-container {
  padding: 0 3.5rem 3.5rem;
}

.specify-time {
  display: flex;
  align-items: center;
  width: 35%;
}

.rdrNextPrevButton.rdrPprevButton {
  left: -3% !important;
}

.rdrCalendarWrapper {
  position: relative !important;
  display: inline !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  border: none !important;
}

.rdrMonthAndYearPickers {
  display: none !important;
}

.rdrMonths {
  width: 100%;
  overflow: hidden;
  margin: 0 0 0 -0.25rem;
}

.rdrWeekDay {
  margin: 0.5rem;
}

.content-container .bottom-container {
  text-align: center;
}

.bottom-container button {
  border: 1px solid #f03a6c;
  width: 5rem;
  margin: 0 0.5rem;
}

.bottom-container .btn-search button,
.bottom-container .btn-search button:hover {
  background-color: #f03a6c;
  color: white;
}

.bottom-container .btn-clear button,
.bottom-container .btn-clear button:hover {
  border: 1px solid #f03a6c;
  color: #f03a6c;
  background-color: white;
}

.doctor-container .tooltip-parent {
  padding: 0 3rem;
}

.rdrMonth {
  margin: 0 0.5rem 0 0;
  padding: 0 !important;
}

.rdrMonths .rdrMonthsHorizontal {
  padding: 1rem !important;
}

.rdrDayWeekend .rdrStartDate.rdrEndDate + .rdrDayNumber span {
  color: white !important;
}

.rdrStartEdge.rdrEndEdge {
  opacity: 0.4 !important;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #f59ab3 !important;
  position: absolute;
  top: 10% !important;
  height: 85% !important;
  z-index: 1 !important;
}

.rdrMonths {
  display: flex;
  padding: 1rem;
}

.rdrMonth {
  margin: 0 0.5rem 0 0;
}

.rdrNextButton {
  right: -3% !important;
}

.rdrPrevButton {
  left: -3% !important;
}

.rdrNextPrevButton:hover {
  background: #f03a6c !important;
}

.rdrWeekDays {
  padding: 0 !important;
}

.rdrWeekDay:first-child,
.rdrDayStartOfWeek span {
  color: #b60a38 !important;
}

.custom-date.custom-date {
  color: red !important;
}

.rdrWeekDay:last-child,
.rdrDayEndOfWeek span {
  color: #147bc1 !important;
}

.rdrMonthAndYearWrapper {
  height: 0 !important;
}

.rdrDayPassive span,
.rdrDayDisabled span {
  color: #d5dce0 !important;
}

.rdrWeekDay input {
  display: unset !important;
}

.rdrWeekDay {
  display: grid;
  padding: 0 0 0.5rem;
}
@media screen and (max-width: 900px) {
  .available-shift-container.container {
    width: 90%;
  }
}

@media only screen and (min-width: 597px) {
  .available-shift-container .container {
    width: 60%;
    margin: 0 auto;
  }

  .content-available-shifts {
    padding: 2rem 1rem;
  }

  .content-container .content-title {
    width: 20%;
  }

  .clinic-container .content-item {
    width: 20%;
    float: left;
  }

  .work-hour-container .content-item {
    width: 25%;
  }

  .work-hour-container .ant-radio-wrapper {
    width: 20%;
  }

  .content-row > * {
    margin: 0 0 0.5rem;
  }

  .rdrDays,
  .rdrMonth,
  .rdrMonth .rdrWeekDays {
    width: 40vh;
  }
}

@media only screen and (max-width: 596px) {
  .container {
    min-width: unset !important;
    max-width: 596px !important;
    width: 100%;
    border: none !important;
    padding: 0.1rem !important;
  }

  .content-row > * {
    padding: 0 0 0.5rem 0.5rem;
  }

  .content-available-shifts {
    padding: 2rem 0;
  }

  .p-tabview-nav {
    display: flex;
  }

  .p-tabview-nav .p-unselectable-text,
  .specify-time label {
    width: 50%;
  }

  .work-hour-container .content-row {
    display: block;
  }

  .split-autocomplete,
  .specify-time {
    width: 100%;
  }

  .clinic-container .content-row {
    display: inline-table;
  }

  .clinic-container .content-item {
    margin-left: 0;
    width: 50%;
    float: left;
  }

  .content-title {
    margin-left: 0px;
    width: 100%;
  }

  .doctor-content.show,
  .doctor-left,
  .content-header {
    padding: 1rem;
  }

  .calendar-control,
  .doctor-container .tooltip-parent {
    padding: 0;
  }

  .content-container.calendar-container {
    padding: 0.3rem;
  }
  .rdrMonths {
    margin: 0 !important;
  }
  .rdrMonth {
    margin: 0 !important;
  }
  .rdrNextPrevButton.rdrNextButton {
    right: -10% !important;
  }

  .rdrNextPrevButton.rdrPprevButton {
    left: -10% !important;
  }

  .p-tabview-title {
    font-size: 12px;
  }

  .content-container:not(.content-container.calendar-container) {
    padding: 1rem 0;
    font-size: 12px;
  }

  .content-container.clinic-container label {
    margin: 0;
  }
}

@media only screen and (max-width: 320px) {
  .rdrNextPrevButton.rdrNextButton {
    right: -15% !important;
  }

  .rdrNextPrevButton.rdrPprevButton {
    left: -15% !important;
  }

  .content-container:not(.content-container.calendar-container),
  .p-tabview-title {
    font-size: 11px;
  }
}
