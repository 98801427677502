.monthly-doctor-salary-confirm {
  .filter {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    &_input {
      min-width: 550px;
    }

    input {
      width: 200px;
      margin-right: 20px;
    }
  }

  .header {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;

    &_title {
      line-height: 33px;
      font-size: 28px;
      padding: 0 50px;
    }
  }

  .dataTable{
    &_cell {
      font-size: 14px;
      height: 43px;
    }
    &_header > .p-column-title {
      font-size: 14px;
    }

    table {
      table-layout: unset !important;
    }
  }
}
