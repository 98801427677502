.detail-waiting-recruitment {
  .content-row {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: flex;
    b {
      margin-right: 2rem !important;
      white-space: nowrap;
      width: 25%;
    }
    .content-value {
      width: 75%;
    }
  }

  .small-modal-content.modal-content {
    padding-top: 0;
  }

  .btn-main-color {
    color: #fff !important;
    background-color: #f03a6c !important;
    border-color: #f03a6c !important;
    :hover {
      background-color: #fff !important;
      color: #f03a6c !important;
    }
  }

  .button-row {
    margin-top: 3rem;
    text-align: center;
  }

  .update-waiting-recruitment-btn {
    margin-left: 1rem;
  }
}
