.expectation-double-recruitment-record-pattern-data-table {
    width: 1080px;
    height: 238px;
    overflow: hidden;
}
.expectation-double-recruitment-record-pattern-data-table table {
    border-collapse: collapse;
    table-layout: fixed;
}
.expectation-double-recruitment-record-pattern-data-table th,
.expectation-double-recruitment-record-pattern-data-table td {
    border: 1px solid rgb(200, 200, 200);
    border-collapse: collapse;
}

.expectation-double-recruitment-record-pattern-data-table table thead, .expectation-double-recruitment-record-pattern-data-table table tbody th tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.expectation-double-recruitment-record-pattern-data-table th {
    font-size: 12px;
    color: rgb(51, 51, 51);
    font-weight: 700;
}
.expectation-double-recruitment-record-pattern-data-table td {
    font-size: 12px;
    color: rgb(51, 51, 51);
    line-height: 1.2;
    padding: 4px 8px;
}

.expectation-double-recruitment-record-pattern-data-table td:not(:first-child) {
    text-align: center;
}

.expectation-double-recruitment-record-pattern-data-table input {
    width: auto;
    border: none;
    color: #000000;
    height: 30px;
    outline: none;
}
.expectation-double-recruitment-record-pattern-data-table table tbody {
    overflow-y: auto;
    height: 196px;
}
.expectation-double-recruitment-record-pattern-data-table table tbody,
.expectation-double-recruitment-record-pattern-data-table table thead {
    display: block;
    width: 1080px;
}
.expectation-double-recruitment-record-pattern-data-table table thead th:first-child,
.expectation-double-recruitment-record-pattern-data-table table tbody td:first-child{
    width: 425px;
}
.expectation-double-recruitment-record-pattern-data-table table thead th:nth-child(2), .expectation-double-recruitment-record-pattern-data-table table tbody td:nth-child(2) {
    width: 53px;
}
.expectation-double-recruitment-record-pattern-data-table table tbody td:nth-child(3) {
    width: 47px;
}

.expectation-double-recruitment-record-pattern-data-table table thead th:nth-child(n+4),
.expectation-double-recruitment-record-pattern-data-table table tbody td:nth-child(n+4) {
    width: 67px;
}