.menu-left-inner{
    display: flex;
    flex-direction: column;
    margin-top: 10px
}

.menu-left-link{
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}
.menu-left-link:hover{
    color: #686868;
}
.menu-left-link i {
    color: var(--primary);
}
.p-accordion-content .menu-left-link p {
    margin-bottom: 0;
}
.menu-left-dropdown{
    background-color: white !important;
}

.p-accordion-header a {
    background-color: white !important;
    /* color: #fff !important; */
    color: var(--black);
    outline: none !important;
    box-shadow: none !important;
    font-size: 18px;
    border: none !important;
  }

.p-accordion-header-text {
    text-transform: capitalize;
  }
.pi-caret-right {
  color: var(--primary) !important;
  float: right;
}
/* .p-accordion .p-accordion-header a{
    border: none;
    background-color: white !important;
    &.p-accordion-toggle-icon{
        color: var(--primary);
    }

}

.p-accordion-header-text {
    display: flex;

} */
.doctor-layout-menu .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a, 
.menu-left-inner .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a, 
.doctor-layout-menu span.p-accordion-header-text, .doctor-layout-menu-link {
    color: black !important;

}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-toggle-icon{
    color: var(--primary) !important;
    float: right;
}
.newMsg{
    background-color: var(--primary);
    color: white;
    border-radius: 23px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
}
.newMsg-text{
    font-size: 10px;
    padding: 4px;
}
p.user-display-dropdown {
    color: #666;
}

.doctor-layout-menu-link.is-selected {
    background-color: #ffebeb;
    color: #666;
    font-size: 16px;
    padding: 0.881em 1em;
}