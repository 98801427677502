.table-result{
  width: 70%;
}
.table-container{
  padding: 0px;
  margin: 15px 0px;
}
.table-result .row{
  flex-direction: column;
}
.table-result .row .tag-name{
  width: fit-content;
  margin-bottom: 0px;
}
.table-result .row .tag-name .update{
  width: fit-content;
}
@media (max-width: 1070px) {

    .table-container{
      padding: 0;
    }
  }