.clinic-mobile-wrapper{
    padding: 10px;
}
.clinic-mobile-inner{
    margin-bottom: 20px;
}
.clinic-mobile-row{
    border: 1px solid var(--borderColor);
    margin : 0;
}
.clinic-mobile-col{
    display:flex;
    padding: 5px 10px;
    align-items: center;
}
.clinic-mobile-col p {
    margin:0 10px;
}
.clinic-mobile-col p:first-child {
    font-weight: 600;
}