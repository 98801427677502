.transportation-detail-table .th,
.transportation-detail-table .td {
  width: 25%;
}

.car-transport-periodic-detail-table .th,
.car-transport-periodic-detail-table .td {
  width: 30%;
}

.top-right-btn {
  margin: 0 0 0 70%;
}
@media screen and (max-width: 425px) {
  .top-right-btn {
    margin: 0 10% 0 40%;
  }
}

.transportation-detail-table .td input {
  width: 70%;
  margin: 0 0.5rem 0 0;
}

.schedule-calendar .background-grey .ant-select-selector {
  padding: 0px;
}
