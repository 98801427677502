.doctor-layout-footer {
  background-color: #FFF9FB;
  color: var(--black);
}

.doctor-layout-footer i {
  cursor: pointer;
  vertical-align: middle;
  display: none;
}

.doctor-layout-footer label,
.doctor-layout-footer h3 {
  font-size: 1.17em;
  font-weight: bold;
}

.doctor-layout-footer a {
  color: var(--black) !important;
}

.doctor-layout-footer a:hover {
  color: #7D7D7D !important;
}

.doctor-layout-footer ul {
  list-style-type: none;
  padding: 0;
}

.footer-top,
.footer-bottom {
  border-top: 1px solid #c8c8c8;
}

.footer-content-header {
  display: flex;
  position: relative;
}

.footer-content-header span {
  position: absolute;
  left: 95%;
}

.footer-header-icon {
  margin-left: auto;
}

input[type=checkbox] {
  display: none;
}

.footer-bottom {
  padding: 1rem;
  display: block;
}

.footer-content-header label {
  padding: 0 0 0.5rem 0;
}

.footer-content-col ul li,s
.footer-bottom a {
  padding: 0.5rem 0;
  white-space: pre-line;
}

@media only screen and (max-width: 1024px) {
  #footer-container {
    width: 90%;
  }
}

@media only screen and (min-width: 597px) {
  .footer-container {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    width: 70%;
    margin: 0 auto;
  }

  .footer-content-col {
    width: 20%;
    margin-left: 1rem;
  }

  .footer-bottom h3 {
    text-align: center;
  }

  .footer-bottom div {
    justify-content: center;
  }
}

@media only screen and (max-width: 596px) {
  body {
    overflow-y: auto;
  }

  .footer-container {
    display: block;
    padding-bottom: 1rem;
    margin: 0 auto;
  }

  .footer-container .footer-content-col:not(:first-child) {
    border-top: 1px solid rgb(151, 148, 148);
  }

  .footer-content-col {
    width: 100%;
    padding: 1rem;
  }

  .footer-content-col p {
    padding-left: 2rem;
  }

  .doctor-layout-footer i {
    display: inline;
  }

  .doctor-layout-footer ul {
    padding-left: 1rem;
  }

  [id^='footer-header-'] {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.3s ease;
  }

  #menu-toggle-1:checked+#footer-header-1,
  #menu-toggle-2:checked+#footer-header-2,
  #menu-toggle-3:checked+#footer-header-3,
  #menu-toggle-4:checked+#footer-header-4,
  #menu-toggle-5:checked+#footer-header-5 {
    max-height: 255px;
  }
}